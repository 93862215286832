import React, { useEffect, useState, useMemo } from "react";
import { Constants } from "../../../../config";
import { StyleSheet, View } from "react-native-web";
import { useTranslation } from "react-i18next";
import { CTabSelector, ReadMoreText } from "../../../../components/atoms";
import Image from "next/image";
import {
  CardList,
  TagCategoryViewModal,
  TagIconSectionList,
} from "../../../../components/molecules";
import { useRouter } from "next/router";
import moment from "moment";
import { uniqBy } from "lodash";
import Link from "next/link";
import { EventDao } from "../../../../DAO";
import { generateURLSlug } from "../../../../../helpers";
import dynamic from "next/dynamic";
import {
  formatCurrency,
  formatNumber,
  formatTagRange,
} from "../../../../helpers/formatHelpers";

const MapView = dynamic(
  () => import("../../../../components/molecules/MapView.js"),
  {
    ssr: false,
  }
);
// const PopularityCardView = dynamic(() => import("./PopularityCardView"), {
//   ssr: false,
// });
const LineupCardView = dynamic(() => import("./LineupCardView"), {
  ssr: false,
});
const WallCardView = dynamic(() => import("./WallCardView"), {
  ssr: false,
});
const VenueCardView = dynamic(() => import("./VenueCardView"), {
  ssr: false,
});
const EventCardView = dynamic(() => import("./EventCardView"), {
  ssr: false,
});
const MemoryCardView = dynamic(() => import("./MemoryCardView"), {
  ssr: false,
});
const MediaCardView = dynamic(() => import("./MediaCardView"), {
  ssr: false,
});
const EditionCardView = dynamic(() => import("./EditionCardView"), {
  ssr: false,
});
const SubListCardView = dynamic(() => import("./SubListCardView"), {
  ssr: false,
});
const BottomInfoView = dynamic(() => import("./BottomInfoView"), {
  ssr: false,
});
const NearByFestivalView = dynamic(() => import("../NearByFestivalView"), {
  ssr: false,
});
const NearByOtherHomeView = dynamic(() => import("../NearByOtherHomeView"), {
  ssr: false,
});
const NearByVenueView = dynamic(() => import("../NearByVenueView"), {
  ssr: false,
});
const GuideProfile = dynamic(() => import("../GuideProfile"), {
  ssr: false,
});
const SpotifyListContainer = dynamic(
  () => import("../../../spotify/SpotifyListContainer"),
  {
    ssr: false,
  }
);

const ProfileAbout = (props) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tagCategories, setTagCategories] = useState([]);
  const [showTagCategoryModal, setShowTagCategoryModal] = useState(false);
  const [detailTagCategory, setDetailCategory] = useState(null);
  const [editionTotal, setEditionTotal] = useState(0);
  const navigation = useRouter();
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  const {
    description,
    tags,
    accomodationUrl,
    transportUrl,
    spotifyId,
    mapLink,
    status,
    capacity,
    roomCount,
    contact,
    contactWebsite_follow_link,
    isOfficial,
    isPro,
    amenities,
  } = props.item;

  const {
    location,
    longitude,
    latitude,
    city,
    venue,
    address,
    postcode,
    nationality,
  } = props.item;

  const lineupPoster = props?.item?.lineup?.lineupPoster;
  const getAddress = (address, venue, city, country) => {
    const addressList = [];
    if (address && address != undefined) {
      addressList.push(address);
    }
    if (venue && venue != undefined) {
      addressList.push(venue);
    }
    if (city && city != undefined) {
      addressList.push(city);
    }
    if (country && country != undefined) {
      addressList.push(country);
    }
    return addressList.join(", ");
  };
  const _address = useMemo(
    () => getAddress(address, venue, city, nationality),
    [city, venue, address, nationality]
  );
  const _location = {
    longitude: longitude || 51.501246,
    latitude: latitude || -0.127161,
  };
  const { category } = props;

  const isShowLineupCard =
    category === Constants.categoryType.CATEGORY_FESTIVAL ||
    category === Constants.categoryType.CATEGORY_EVENT;
  const isShowWall = false;
  const isShowMemories = false;
  const isTBC =
    status === Constants.festivalStatus.FE_STAT_UPCOMMING_TO_BE_UPDATED;
  const isReschedule =
    status === Constants.festivalStatus.FE_STAT_RESCHEDULE_TO_BE_UPDATED;
  const isShowMapView =
    (category == Constants.categoryType.CATEGORY_EVENT ||
      category == Constants.categoryType.CATEGORY_FESTIVAL ||
      category == Constants.categoryType.CATEGORY_VENUE ||
      category == Constants.categoryType.CATEGORY_ROOFTOP ||
      category == Constants.categoryType.CATEGORY_SPA ||
      category == Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
      category == Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
      category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
      category === Constants.categoryType.CATEGORY_ZOO ||
      category === Constants.categoryType.CATEGORY_PARKFUL ||
      category === Constants.categoryType.CATEGORY_CASINO ||
      category === Constants.categoryType.CATEGORY_CASTLE ||
      category === Constants.categoryType.CATEGORY_RESORT ||
      category === Constants.categoryType.CATEGORY_APARTHOTEL) &&
    !isTBC &&
    !isReschedule;
  const isShowSpotifyPlayer =
    category == Constants.categoryType.CATEGORY_ARTIST && spotifyId;
  const isArtist = category == Constants.categoryType.CATEGORY_ARTIST;
  const isShowNearByFestival =
    category == Constants.categoryType.CATEGORY_FESTIVAL ||
    category == Constants.categoryType.CATEGORY_EVENT;
  const isShowNearByVenue =
    category != Constants.categoryType.CATEGORY_FESTIVAL &&
    category != Constants.categoryType.CATEGORY_EVENT &&
    category != Constants.categoryType.CATEGORY_ARTIST &&
    category != Constants.categoryType.CATEGORY_PREFAB_HOMES &&
    category != Constants.categoryType.CATEGORY_ANIMAL;
  const constructMenuItems = (tagGroups) => {
    // if (process.env.NEXT_PUBLIC_APP_NAME !== "Festyful") {
    return [{ title: "Features", count: 0, withoutCount: true }];
    // } else {
    //   if (!tagGroups) return [];
    //   return tagGroups.map((tagGroup) => {
    //     return {
    //       title: tagGroup.title,
    //       count:
    //         tagGroup.children.filter(
    //           (i) =>
    //             i.category !== Constants.tagCategory.TAG_CATEGORY_FLOOR &&
    //             i.category !== Constants.tagCategory.TAG_CATEGORY_VIEW &&
    //             category == Constants.categoryType.CATEGORY_ROOFTOP &&
    //             i.category !== Constants.tagCategory.TAG_CATEGORY_ACCESS
    //         ).length +
    //         (tagGroup.title === "Venue"
    //           ? category === Constants.categoryType.CATEGORY_SPA
    //             ? 2
    //             : 1
    //           : 0), //Add website icon tag for Venue
    //     };
    //   });
    // }
  };

  const isShowVenues = props.item.venues != undefined;

  const loadEdition = () => {
    const parameters = {
      editionEventId: props.item.id,
      sortType: Constants.sortByAction.SORT_BY_LATE,
    };
    EventDao.findWeb(parameters)
      .then((result) => {
        setEditionTotal(result.data.length);
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  useEffect(() => {
    onSelectTab(0);
    if (category === Constants.categoryType.CATEGORY_FESTIVAL) {
      loadEdition();
    }
  }, [JSON.stringify(props.item)]);

  const menuItems = constructMenuItems(tags);

  const onSelectTab = (currentIndex) => {
    // if (process.env.NEXT_PUBLIC_APP_NAME !== "Festyful") {
    if (tags && tags.length > 0) {
      let result = [];
      tags.forEach((item, index) => {
        const tagGroup = tags[index];
        const _tagCategories =
          tagGroup?.children?.filter(
            (i) =>
              i.category !== Constants.tagCategory.TAG_CATEGORY_FLOOR &&
              i.category !== Constants.tagCategory.TAG_CATEGORY_VIEW
          ) || [];

        result = result.concat(_tagCategories);
      });
      setTagCategories(result);
    }
    // } else {
    //   setSelectedTabIndex(currentIndex);
    //   if (tags && tags.length > currentIndex) {
    //     const tagGroup = tags[currentIndex];
    //     const _tagCategories =
    //       tagGroup.children.filter(
    //         (i) =>
    //           i.category !== Constants.tagCategory.TAG_CATEGORY_FLOOR &&
    //           i.category !== Constants.tagCategory.TAG_CATEGORY_VIEW
    //       ) || [];

    //     setTagCategories(_tagCategories);
    //   }
    // }
  };

  const onPressTagItem = (item) => {
    if (item && item.children && !item.withoutClick) {
      setDetailCategory(item);
      setShowTagCategoryModal(true);
    }
  };

  const navigateToProfileBottomScreen = (bottomMenuType) => {
    if (props.changeTabType) {
      props.changeTabType(bottomMenuType);
    }
  };

  const getDeclaimText = (profileCategory) => {
    if (
      profileCategory == Constants.categoryType.CATEGORY_FESTIVAL ||
      profileCategory == Constants.categoryType.CATEGORY_EVENT
    ) {
      return t("key_declaim_festival");
    } else if (profileCategory == Constants.categoryType.CATEGORY_ARTIST) {
      return t("key_declaim_artist");
    } else if (
      profileCategory == Constants.categoryType.CATEGORY_VENUE ||
      profileCategory == Constants.categoryType.CATEGORY_ROOFTOP ||
      profileCategory == Constants.categoryType.CATEGORY_SPA ||
      profileCategory == Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
      profileCategory == Constants.categoryType.CATEGORY_VENUE_COCKTAIL
    ) {
      return t("key_declaim_venue");
    } else if (
      profileCategory === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
      profileCategory === Constants.categoryType.CATEGORY_PREFAB_HOMES
    ) {
      return t("key_declaim_prefab");
    } else if (
      profileCategory === Constants.categoryType.CATEGORY_ZOO ||
      profileCategory === Constants.categoryType.CATEGORY_ANIMAL
    ) {
      return t("key_declaim_zoopedia");
    } else if (profileCategory === Constants.categoryType.CATEGORY_PARKFUL) {
      return t("key_declaim_parkful");
    } else if (profileCategory === Constants.categoryType.CATEGORY_CASINO) {
      return t("key_declaim_casino");
    } else if (profileCategory === Constants.categoryType.CATEGORY_CASTLE) {
      return t("key_declaim_castle");
    } else if (profileCategory === Constants.categoryType.CATEGORY_RESORT) {
      return t("key_declaim_resort");
    } else if (profileCategory === Constants.categoryType.CATEGORY_APARTHOTEL) {
      return t("key_declaim_aparthotel");
    }
  };

  const getTagCategories = () => {
    let newTagCategories = tagCategories
      .filter(
        (i) =>
          i.category !== Constants.tagCategory.TAG_CATEGORY_FLOOR &&
          i.category !== Constants.tagCategory.TAG_CATEGORY_VIEW
      )
      .map((i) => {
        if (
          (i.category === Constants.tagCategory.TAG_CATEGORY_SIZE ||
            i.category === Constants.tagCategory.TAG_CATEGORY_ROOFTOP_SIZE) &&
          (category === Constants.categoryType.CATEGORY_VENUE ||
            category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
            category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
            category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
            category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
            category === Constants.categoryType.CATEGORY_ZOO ||
            category === Constants.categoryType.CATEGORY_ANIMAL ||
            category === Constants.categoryType.CATEGORY_PARKFUL ||
            category === Constants.categoryType.CATEGORY_CASINO ||
            category === Constants.categoryType.CATEGORY_CASTLE ||
            category === Constants.categoryType.CATEGORY_RESORT ||
            category === Constants.categoryType.CATEGORY_APARTHOTEL)
        ) {
          // if (i.children.length > 0) {
          //   let children = [
          //     ...i.children,
          //     capacity
          //       ? {
          //           id: i.children[0] + 1,
          //           pid: 500,
          //           level: 2,
          //           type: 0,
          //           title: `Capacity - ${capacity}`,
          //           description: null,
          //           icon: "",
          //           order: 0,
          //           category: 0,
          //           filter_slug: `Capacity - ${capacity}`,
          //           createdAt: null,
          //           updatedAt: null,
          //           name: `Capacity - ${capacity}`,
          //           leafCount: 1,
          //           isLeaf: true,
          //           minValue: null,
          //           maxValue: null,
          //           unit: null,
          //         }
          //       : null,
          //     roomCount
          //       ? {
          //           id: i.children[0] + 2,
          //           pid: 500,
          //           level: 2,
          //           type: 0,
          //           title: `Rooms - ${roomCount}`,
          //           description: null,
          //           icon: "",
          //           order: 0,
          //           category: 0,
          //           filter_slug: `Rooms - ${roomCount}`,
          //           createdAt: null,
          //           updatedAt: null,
          //           name: `Rooms - ${roomCount}`,
          //           leafCount: 1,
          //           isLeaf: true,
          //           minValue: null,
          //           maxValue: null,
          //           unit: null,
          //         }
          //       : null,
          //   ];
          //   return { ...i, children: children.filter((i) => i) };
          // } else {
          return i;
          // }
        } else if (
          (category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
            category === Constants.categoryType.CATEGORY_ROOFTOP ||
            category === Constants.categoryType.CATEGORY_SPA ||
            category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
            category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
            category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
            category === Constants.categoryType.CATEGORY_ZOO ||
            category === Constants.categoryType.CATEGORY_ANIMAL ||
            category === Constants.categoryType.CATEGORY_PARKFUL ||
            category === Constants.categoryType.CATEGORY_CASINO ||
            category === Constants.categoryType.CATEGORY_CASTLE ||
            category === Constants.categoryType.CATEGORY_RESORT ||
            category === Constants.categoryType.CATEGORY_APARTHOTEL) &&
          (i.category === Constants.tagCategory.TAG_CATEGORY_VENUE ||
            i.category === Constants.tagCategory.TAG_CATEGORY_VENUE_2)
        ) {
          return { ...i, icon: "Local:iconTagGeneral" };
        } else {
          return i;
        }
      });

    if (
      amenities?.length > 0 &&
      (category === Constants.categoryType.CATEGORY_SPA ||
        category === Constants.categoryType.CATEGORY_PARKFUL ||
        category === Constants.categoryType.CATEGORY_CASTLE)
    ) {
      newTagCategories.push({
        id: -1,
        pid: 1,
        level: 1,
        type: 0,
        title:
          category === Constants.categoryType.CATEGORY_CASTLE
            ? "Features"
            : `Amenities`,
        description: null,
        icon:
          category === Constants.categoryType.CATEGORY_CASTLE
            ? "Local:iconTagFeature"
            : category === Constants.categoryType.CATEGORY_SPA ||
              category === Constants.categoryType.CATEGORY_PARKFUL
            ? "Local:iconTagAmenities"
            : "Local:iconTagStatus",
        order: 0,
        category: 9,
        filter_slug:
          category === Constants.categoryType.CATEGORY_CASTLE
            ? "Features"
            : `Amenities`,
        createdAt: null,
        updatedAt: null,
        name:
          category === Constants.categoryType.CATEGORY_CASTLE
            ? "Features"
            : `Amenities`,
        children: amenities.map((item) => ({
          id: 1,
          pid: 500,
          level: 2,
          type: 0,
          title: item.name,
          description: null,
          icon: "",
          order: 0,
          category: 0,
          filter_slug: item.name,
          createdAt: null,
          updatedAt: null,
          name: item.name,
          leafCount: 1,
          isLeaf: true,
          minValue: null,
          maxValue: null,
          unit: null,
        })),
        leafCount: 0,
      });
    }

    if (
      // selectedTabIndex === 0 &&
      (category === Constants.categoryType.CATEGORY_FESTIVAL ||
        category === Constants.categoryType.CATEGORY_EVENT ||
        category === Constants.categoryType.CATEGORY_VENUE ||
        category === Constants.categoryType.CATEGORY_ROOFTOP ||
        category === Constants.categoryType.CATEGORY_SPA ||
        category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
        category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
        category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
        category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
        category === Constants.categoryType.CATEGORY_ZOO ||
        category === Constants.categoryType.CATEGORY_ANIMAL ||
        category === Constants.categoryType.CATEGORY_PARKFUL ||
        category === Constants.categoryType.CATEGORY_CASINO ||
        category === Constants.categoryType.CATEGORY_CASTLE ||
        category === Constants.categoryType.CATEGORY_RESORT ||
        category === Constants.categoryType.CATEGORY_APARTHOTEL) &&
      contact?.website
    ) {
      newTagCategories.push({
        id: -1,
        pid: 1,
        level: 1,
        type: 0,
        title: "Website",
        description: null,
        icon: contactWebsite_follow_link
          ? "Local:iconWebsiteColored"
          : "Local:iconWebsiteGray",
        order: 99,
        category: 9,
        filter_slug: "Website",
        createdAt: null,
        updatedAt: null,
        name: "Website",
        value: !contact?.website?.includes("http")
          ? `https://${contact?.website}`
          : contact?.website,
        contactWebsite_follow_link,
        children: [],
        leafCount: 0,
      });
    }
    if (
      newTagCategories.filter(
        (i) => i.category === Constants.tagCategory.TAG_CATEGORY_AGE
      )?.length > 0
    ) {
      const newTagCategoriesWithoutAge = newTagCategories.filter(
        (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_AGE
      );
      newTagCategoriesWithoutAge.push({
        ...newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_AGE
        ),
        children: [
          {
            ...newTagCategories.find(
              (i) => i.category === Constants.tagCategory.TAG_CATEGORY_AGE
            )?.children[0],
            name: `+${
              newTagCategories
                .find(
                  (i) => i.category === Constants.tagCategory.TAG_CATEGORY_AGE
                )
                ?.children[0]?.minValue?.toString()
                ?.split(".")[0]
            }`,
            title: `+${
              newTagCategories
                .find(
                  (i) => i.category === Constants.tagCategory.TAG_CATEGORY_AGE
                )
                ?.children[0]?.minValue?.toString()
                ?.split(".")[0]
            }`,
          },
        ],
      });
      newTagCategories = newTagCategoriesWithoutAge;
    }
    if (category === Constants.categoryType.CATEGORY_ROOFTOP) {
      return newTagCategories.filter(
        (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_ACCESS
      );
    } else if (
      category === Constants.categoryType.CATEGORY_VENUE ||
      category === Constants.categoryType.CATEGORY_SPA
    ) {
      const result = newTagCategories
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_VENUE)
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_VENUE_2
        );
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_VENUE
        )?.children?.length > 0
      ) {
        result.push({
          ...newTagCategories.find(
            (i) => i.category === Constants.tagCategory.TAG_CATEGORY_VENUE
          ),
          children: newTagCategories
            .find(
              (i) => i.category === Constants.tagCategory.TAG_CATEGORY_VENUE
            )
            ?.children?.concat(
              newTagCategories.find(
                (i) => i.category === Constants.tagCategory.TAG_CATEGORY_VENUE_2
              )?.children
            ),
        });
      }
      return result;
    } else if (category === Constants.categoryType.CATEGORY_PREFAB_HOMES) {
      let result = newTagCategories
        .filter(
          (i) =>
            i.category !==
            Constants.tagCategory.TAG_CATEGORY_PREFAB_DIMENSION_LENGTH
        )
        .filter(
          (i) =>
            i.category !==
            Constants.tagCategory.TAG_CATEGORY_PREFAB_DIMENSION_WIDTH
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_OTHER_ROOM
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_ASSEMBLING
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_BEDROOM
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_BATHROOM
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_VARIANT
        )
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_MODULE
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_STORIES
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_CAPACITY
        )
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_CATEGORY
        )
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_PRICE);
      result.push({
        id: -1,
        pid: 1,
        level: 1,
        type: 0,
        description: null,
        icon: "Local:iconTagGeneral",
        order: 66,
        category: 9,
        createdAt: null,
        updatedAt: null,
        children: [],
        leafCount: 0,
        withoutClick: true,
        title: null,
      });
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_CATEGORY
        )?.children?.length > 0
      ) {
        const children = [];
        newTagCategories
          .filter(
            (i) =>
              i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_CATEGORY
          )
          .map((item) => {
            children.push(item.children[0]);
          });

        result.push({
          ...newTagCategories.find(
            (i) =>
              i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_CATEGORY
          ),
          children: uniqBy(children, "id"),
        });
      }
      if (
        newTagCategories.filter(
          (i) =>
            i.category ===
              Constants.tagCategory.TAG_CATEGORY_PREFAB_DIMENSION_LENGTH ||
            i.category ===
              Constants.tagCategory.TAG_CATEGORY_PREFAB_DIMENSION_WIDTH
        )?.length > 0
      ) {
        result.push({
          title: `Dimension | ${
            newTagCategories.find(
              (i) =>
                i.category ===
                Constants.tagCategory.TAG_CATEGORY_PREFAB_DIMENSION_WIDTH
            )?.children[0]?.label
              ? newTagCategories.find(
                  (i) =>
                    i.category ===
                    Constants.tagCategory.TAG_CATEGORY_PREFAB_DIMENSION_WIDTH
                )?.children[0]?.label + " X "
              : ""
          }${
            newTagCategories.find(
              (i) =>
                i.category ===
                Constants.tagCategory.TAG_CATEGORY_PREFAB_DIMENSION_LENGTH
            )?.children[0]?.label
          } ft`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_OTHER_ROOM
        )?.children?.length > 0
      ) {
        result.push({
          title: `Other rooms | ${
            newTagCategories.find(
              (i) =>
                i.category ===
                Constants.tagCategory.TAG_CATEGORY_PREFAB_OTHER_ROOM
            )?.children[0]?.name
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_BEDROOM
        )?.children?.length > 0
      ) {
        result.push({
          title: `Bedrooms | ${
            newTagCategories.find(
              (i) =>
                i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_BEDROOM
            )?.children[0]?.name
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_BATHROOM
        )?.children?.length > 0
      ) {
        result.push({
          title: `Bathrooms | ${
            newTagCategories
              .find(
                (i) =>
                  i.category ===
                  Constants.tagCategory.TAG_CATEGORY_PREFAB_BATHROOM
              )
              ?.children[0]?.minValue?.toString()
              ?.split(".")[0]
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_VARIANT
        )?.children?.length > 0
      ) {
        result.push({
          title: `Variants | ${
            newTagCategories
              .find(
                (i) =>
                  i.category ===
                  Constants.tagCategory.TAG_CATEGORY_PREFAB_VARIANT
              )
              ?.children[0]?.minValue?.toString()
              ?.split(".")[0]
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_CAPACITY
        )?.children?.length > 0
      ) {
        result.push({
          title: `Capacity | ${
            newTagCategories
              .find(
                (i) =>
                  i.category ===
                  Constants.tagCategory.TAG_CATEGORY_PREFAB_CAPACITY
              )
              ?.children[0]?.minValue?.toString()
              ?.split(".")[0]
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_STORIES
        )?.children?.length > 0
      ) {
        result.push({
          title: `Stories | ${
            newTagCategories
              .find(
                (i) =>
                  i.category ===
                  Constants.tagCategory.TAG_CATEGORY_PREFAB_STORIES
              )
              ?.children[0]?.minValue?.toString()
              ?.split(".")[0]
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_MODULE
        )?.children?.length > 0
      ) {
        result.push({
          title: `Modules | ${
            newTagCategories
              .find(
                (i) =>
                  i.category ===
                  Constants.tagCategory.TAG_CATEGORY_PREFAB_MODULE
              )
              ?.children[0]?.minValue?.toString()
              ?.split(".")[0]
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_PRICE
        )?.children?.length > 0
      ) {
        result.push({
          title: `From | ${formatCurrency(
            formatNumber(
              newTagCategories.find(
                (i) => i.category === Constants.tagCategory.TAG_CATEGORY_PRICE
              )?.children[0]?.minValue
            )
          )}`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT
        )?.children?.length > 0
      ) {
        result.push({
          title: `Min | ${formatNumber(
            newTagCategories.find(
              (i) => i.category === Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT
            )?.children[0]?.minValue
          )} sqft`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_ASSEMBLING
        )?.children?.length > 0
      ) {
        result.push({
          title: `Assembling | ${
            newTagCategories.find(
              (i) =>
                i.category ===
                Constants.tagCategory.TAG_CATEGORY_PREFAB_ASSEMBLING
            )?.children[0]?.name
          }`,
          isHeading: true,
          order: 66,
        });
      }
      return result;
    } else if (category === Constants.categoryType.CATEGORY_PREFAB_COMPANY) {
      // Call the function to get the concatenated result
      let result = concatChildrenById(
        newTagCategories.filter(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_CATEGORY ||
            i.category ===
              Constants.tagCategory.TAG_CATEGORY_PREFAB_ASSEMBLING ||
            i.title === "Website"
        )
      ).map((i) => ({
        ...i,
        children: uniqBy(i.children, "id"),
      }));

      result = result
        ?.filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_PREFAB_ASSEMBLING
        )
        .concat(
          result
            .filter(
              (i) =>
                i.category ===
                Constants.tagCategory.TAG_CATEGORY_PREFAB_ASSEMBLING
            )
            .map((item) => ({
              ...item,
              title: null,
              name: null,
              filter_slug: null,
              icon: "Local:iconTagGeneral",
              children: item.children.map((itemChild) => ({
                ...itemChild,
                name: `${item.title} | ${itemChild.name}`,
                isHeading: true,
              })),
              isHeading: true,
              withoutClick: true,
              order: 66,
            }))
        );
      return result;
    } else if (category === Constants.categoryType.CATEGORY_ANIMAL) {
      return newTagCategories.filter(
        (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_ANIMAL_CATEGORY
      );
    } else if (category === Constants.categoryType.CATEGORY_ZOO) {
      const result = newTagCategories
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT
        )
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_ZOO_SPECIES
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_ZOO_POPULATION
        )
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_SIZE_SQM
        )
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_PRICE);
      if (
        newTagCategories.filter(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_SIZE_SQM ||
            i.category === Constants.tagCategory.TAG_CATEGORY_ZOO_POPULATION ||
            i.category === Constants.tagCategory.TAG_CATEGORY_ZOO_SPECIES
        )?.length > 0
      ) {
        result.push({
          id: -1,
          pid: 1,
          level: 1,
          type: 0,
          description: null,
          icon: "Local:iconTagGeneral",
          order: 66,
          category: 9,
          createdAt: null,
          updatedAt: null,
          children: [],
          leafCount: 0,
          withoutClick: true,
          title: null,
        });
      }
      result.push({
        title: `Animals | ${formatTagRange(
          Constants.tagCategory.TAG_CATEGORY_ZOO_SPECIES,
          newTagCategories
            .find(
              (i) =>
                i.category === Constants.tagCategory.TAG_CATEGORY_ZOO_SPECIES
            )
            ?.children[0]?.minValue?.toString()
            ?.split(".")[0]
        )}`,
        isHeading: true,
        order: 66,
      });
      result.push({
        title: `Population | ${formatTagRange(
          Constants.tagCategory.TAG_CATEGORY_ZOO_POPULATION,
          newTagCategories
            .find(
              (i) =>
                i.category === Constants.tagCategory.TAG_CATEGORY_ZOO_POPULATION
            )
            ?.children[0]?.minValue?.toString()
            ?.split(".")[0]
        )}`,
        isHeading: true,
        order: 66,
      });
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_SIZE_SQM
        )?.children?.length > 0
      ) {
        result.push({
          title: `Size | ${
            newTagCategories.find(
              (i) => i.category === Constants.tagCategory.TAG_CATEGORY_SIZE_SQM
            )?.children[0]?.name
          }`,
          isHeading: true,
          order: 66,
        });
      }
      return result;
    } else if (category === Constants.categoryType.CATEGORY_PARKFUL) {
      let result = newTagCategories
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT
        )
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_PARKFUL_SIZE
        )
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_PRICE);

      result.push({
        id: -1,
        pid: 1,
        level: 1,
        type: 0,
        description: null,
        icon: "Local:iconTagGeneral",
        order: 66,
        category: 9,
        createdAt: null,
        updatedAt: null,
        children: [],
        leafCount: 0,
        withoutClick: true,
        title: null,
      });
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_PARKFUL_SIZE
        )?.children?.length > 0
      ) {
        result.push({
          title: `Size - ${
            (Constants.tagCategory.TAG_CATEGORY_PARKFUL_SIZE,
            newTagCategories
              .find(
                (i) =>
                  i.category === Constants.tagCategory.TAG_CATEGORY_PARKFUL_SIZE
              )
              ?.children[0]?.name?.split(" - ")[0])
          }`,
          isHeading: true,
          order: 66,
        });
      }
      return result;
    } else if (category === Constants.categoryType.CATEGORY_CASINO) {
      let result = newTagCategories
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_ZOO_SPECIES
        )
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_CASINO_GAMES
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_CASINO_POKER_TABLE
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_CASINO_GAMES_TABLE
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_CASINO_MACHINES
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_CASINO_FEATURE
        )
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT
        )
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_AGE)
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_ENTRY)
        .filter(
          (i) => i.category !== Constants.tagCategory.TAG_CATEGORY_DRESS_CODE
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_CASINO_OPEN_24_7
        )
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_CASINO_PARKING
        );
      result.push({
        ...newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_CASINO_GAMES
        ),
        children: newTagCategories
          .find(
            (i) =>
              i.category === Constants.tagCategory.TAG_CATEGORY_CASINO_GAMES
          )
          ?.children.concat([
            {
              title: `${
                newTagCategories
                  .find(
                    (i) =>
                      i.category ===
                      Constants.tagCategory.TAG_CATEGORY_CASINO_POKER_TABLE
                  )
                  ?.children[0]?.minValue?.toString()
                  ?.split(".")[0] || "N/A"
              } | Poker Tables`,
              isHeading: true,
              order: 0,
              isCountInfo: true,
            },
            {
              title: `${
                newTagCategories
                  .find(
                    (i) =>
                      i.category ===
                      Constants.tagCategory.TAG_CATEGORY_CASINO_GAMES_TABLE
                  )
                  ?.children[0]?.minValue?.toString()
                  ?.split(".")[0] || "N/A"
              } | Games Tables`,
              isHeading: true,
              order: 0,
              isCountInfo: true,
            },
            {
              title: `${
                newTagCategories
                  .find(
                    (i) =>
                      i.category ===
                      Constants.tagCategory.TAG_CATEGORY_CASINO_MACHINES
                  )
                  ?.children[0]?.minValue?.toString()
                  ?.split(".")[0] || "N/A"
              } | Machines`,
              isHeading: true,
              order: 0,
              isCountInfo: true,
            },
          ]),
      });
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_CASINO_FEATURE
        )?.children?.length > 0
      ) {
        result.push({
          ...newTagCategories.find(
            (i) =>
              i.category === Constants.tagCategory.TAG_CATEGORY_CASINO_FEATURE
          ),
        });
      }
      result.push({
        id: -1,
        pid: 1,
        level: 1,
        type: 0,
        description: null,
        icon: "Local:iconTagGeneral",
        order: 66,
        category: 9,
        createdAt: null,
        updatedAt: null,
        children: [],
        leafCount: 0,
        withoutClick: true,
        title: null,
      });
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT
        )?.children?.length > 0
      ) {
        result.push({
          title: `Size | ${formatTagRange(
            Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT,
            newTagCategories
              .find(
                (i) =>
                  i.category === Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT
              )
              ?.children[0]?.minValue?.toString()
              ?.split(".")[0]
          )}`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_AGE
        )?.children?.length > 0
      ) {
        result.push({
          title: `Min Age | ${
            newTagCategories
              .find(
                (i) => i.category === Constants.tagCategory.TAG_CATEGORY_AGE
              )
              ?.children[0]?.minValue?.toString()
              ?.split(".")[0]
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_ENTRY
        )?.children?.length > 0
      ) {
        result.push({
          title: `Entry | ${
            newTagCategories.find(
              (i) => i.category === Constants.tagCategory.TAG_CATEGORY_ENTRY
            )?.children[0]?.name
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_DRESS_CODE
        )?.children?.length > 0
      ) {
        result.push({
          title: `Dress Code | ${
            newTagCategories.find(
              (i) =>
                i.category === Constants.tagCategory.TAG_CATEGORY_DRESS_CODE
            )?.children[0]?.name
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_CASINO_OPEN_24_7
        )?.children?.length > 0
      ) {
        result.push({
          title: `Open 24/7 | ${
            newTagCategories.find(
              (i) =>
                i.category ===
                Constants.tagCategory.TAG_CATEGORY_CASINO_OPEN_24_7
            )?.children[0]?.name
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (
        newTagCategories.find(
          (i) =>
            i.category === Constants.tagCategory.TAG_CATEGORY_CASINO_PARKING
        )?.children?.length > 0
      ) {
        result.push({
          title: `Parking | ${
            newTagCategories.find(
              (i) =>
                i.category === Constants.tagCategory.TAG_CATEGORY_CASINO_PARKING
            )?.children[0]?.name
          }`,
          isHeading: true,
          order: 66,
        });
      }
      if (props.item.founding) {
        result.push({
          title: `Founding year | ${props.item.founding}`,
          isHeading: true,
          order: 66,
        });
      }
      return result;
    } else if (category === Constants.categoryType.CATEGORY_CASTLE) {
      let result = newTagCategories
        .filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_CASTLE_CATEGORY
        )
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_PRICE);
      return result;
    } else if (category === Constants.categoryType.CATEGORY_RESORT) {
      let result = newTagCategories
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_AREA)
        .filter((i) => i.category !== Constants.tagCategory.TAG_CATEGORY_STAR);
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_STAR
        )?.children?.length > 0
      ) {
        result.push({
          ...newTagCategories.find(
            (i) => i.category === Constants.tagCategory.TAG_CATEGORY_STAR
          ),
          order: -1,
        });
      }
      return result;
    } else if (category === Constants.categoryType.CATEGORY_APARTHOTEL) {
      let result = newTagCategories.filter(
        (i) =>
          i.category !== Constants.tagCategory.TAG_CATEGORY_APARTHOTEL_CATEGORY
      );
      return result;
    } else if (category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL) {
      let result = newTagCategories;
      if (
        newTagCategories.find(
          (i) => i.category === Constants.tagCategory.TAG_CATEGORY_ENTRY
        )?.children?.length === undefined
      ) {
        result.push({
          id: 1200,
          pid: 3,
          second_pid: null,
          level: 1,
          type: 0,
          title: "Entry",
          description: null,
          icon: "Local:iconTagEntry",
          order: 1,
          category: 6,
          filter_slug: "Entry",
          createdAt: null,
          updatedAt: null,
          name: "Entry",
          children: [
            {
              id: 1207,
              pid: 1200,
              second_pid: null,
              level: 2,
              type: 0,
              title: "N/A",
              description: null,
              icon: "",
              order: 0,
              category: 6,
              filter_slug: "N/A",
              createdAt: null,
              updatedAt: null,
              name: "N/A",
              leafCount: 1,
              isLeaf: true,
              minValue: null,
              maxValue: null,
              unit: null,
              label: null,
            },
          ],
          leafCount: 1,
        });
      }
      return result;
    } else {
      return newTagCategories;
    }
  };

  // Function to concatenate children by id
  function concatChildrenById(arr) {
    const result = [];
    const idMap = {};

    arr.forEach((item) => {
      const id = item.id;

      if (!idMap[id]) {
        // Initialize a copy of the item with an empty children array
        const copy = { ...item };
        copy.children = [];
        result.push(copy);
        idMap[id] = copy;
      }

      // Concatenate the children
      idMap[id].children = idMap[id].children.concat(item.children);
    });

    return result;
  }

  return (
    <View style={[styles.container, props.style]}>
      {description && (
        <ReadMoreText
          text={description}
          style={styles.readMoreContainer}
          isMobileVersion={isMobileVersion}
        />
      )}

      {menuItems.length > 0 &&
        category !== Constants.categoryType.CATEGORY_ARTIST && (
          <div className={isMobileVersion ? "px-4" : ""}>
            <CTabSelector
              items={menuItems}
              selectedIndex={selectedTabIndex}
              tabItemType="round-tab"
              onSelectTab={(item, index) => {
                onSelectTab(index);
              }}
              containerStyle={{ marginTop: 30, marginHorizontal: 16 }}
              isMobileVersion={isMobileVersion}
              isHeading
              isUppercase
            />
          </div>
        )}

      <div className={isMobileVersion ? "px-4" : ""}>
        <TagIconSectionList
          items={getTagCategories()}
          style={{ marginTop: 30, paddingHorizontal: 16 }}
          onPressItem={onPressTagItem}
          isMobileVersion={isMobileVersion}
        />

        <TagCategoryViewModal
          item={detailTagCategory}
          isVisible={showTagCategoryModal}
          onClose={() => {
            setShowTagCategoryModal(false);
          }}
          isMobileVersion={isMobileVersion}
        />

        <View style={styles.profileTagContainer}>
          {/* <PopularityCardView
            item={props.item}
            isMobileVersion={isMobileVersion}
          /> */}
          {props.item?.event && (
            <Link
              href={generateURLSlug(
                props.item.id,
                props.item.title ||
                  props.item.displayName ||
                  props.item.nickname,
                props.item.startDate,
                category,
                "festivals"
              )}>
              <a>
                <EventCardView
                  item={props.item}
                  style={{ marginTop: isMobileVersion ? 8 : 15 }}
                  onPress={null}
                  isMobileVersion={isMobileVersion}
                />
              </a>
            </Link>
          )}
          {isShowLineupCard && (
            <Link
              href={generateURLSlug(
                props.item.id,
                props.item.title ||
                  props.item.displayName ||
                  props.item.nickname,
                props.item.startDate,
                category,
                "lineup"
              )}>
              <a>
                <LineupCardView
                  item={props.item}
                  style={[
                    {
                      marginTop: isMobileVersion ? 8 : 15,
                      height: 70,
                      borderRadius: 25,
                    },
                    isMobileVersion ? { height: 50, borderRadius: 16 } : {},
                  ]}
                  onPress={null}
                  isMobileVersion={isMobileVersion}
                />
              </a>
            </Link>
          )}
          <MediaCardView
            item={props.item}
            style={[
              {
                marginTop: isMobileVersion ? 8 : 15,
                height: 70,
                borderRadius: 25,
              },
              isMobileVersion ? { height: 50, borderRadius: 16 } : {},
            ]}
            onPress={() => {
              navigateToProfileBottomScreen(Constants.profileTabType.TAB_MEDIA);
            }}
            isMobileVersion={isMobileVersion}
          />
          {category === Constants.categoryType.CATEGORY_FESTIVAL && (
            <EditionCardView
              item={props.item}
              total={editionTotal}
              style={[
                {
                  marginTop: isMobileVersion ? 8 : 15,
                  height: 70,
                  borderRadius: 25,
                },
                isMobileVersion ? { height: 50, borderRadius: 16 } : {},
              ]}
              onPress={() => {
                navigation.push(
                  `${generateURLSlug(
                    props?.item?.id,
                    props?.item?.title,
                    props?.item?.startDate,
                    category,
                    "edition"
                  )}`
                );
              }}
              isMobileVersion={isMobileVersion}
            />
          )}
          {category === Constants.categoryType.CATEGORY_PREFAB_COMPANY && (
            <Link
              href={generateURLSlug(
                props.item.id,
                props.item.title ||
                  props.item.displayName ||
                  props.item.nickname,
                props.item.startDate,
                category,
                "homes"
              )}>
              <a>
                <SubListCardView
                  title="Homes"
                  category={category}
                  item={props.item}
                  style={[
                    {
                      marginTop: isMobileVersion ? 8 : 15,
                      height: 70,
                      borderRadius: 25,
                    },
                    isMobileVersion ? { height: 50, borderRadius: 16 } : {},
                  ]}
                  isMobileVersion={isMobileVersion}
                />
              </a>
            </Link>
          )}
          {/* {category === Constants.categoryType.CATEGORY_PREFAB_HOMES && (
            <Link
              href={generateURLSlug(
                props.item.id,
                props.item.title ||
                  props.item.displayName ||
                  props.item.nickname,
                props.item.startDate,
                category,
                "other-homes"
              )}>
              <a>
                <SubListCardView
                  title="Others"
                  category={category}
                  item={props.item}
                  style={[
                    {
                      marginTop: isMobileVersion ? 8 : 15,
                      height: 70,
                      borderRadius: 25,
                    },
                    isMobileVersion ? { height: 50, borderRadius: 16 } : {},
                  ]}
                  isMobileVersion={isMobileVersion}
                />
              </a>
            </Link>
          )} */}
          {/* {category === Constants.categoryType.CATEGORY_ZOO && (
            <Link
              href={generateURLSlug(
                props.item.id,
                props.item.title ||
                  props.item.displayName ||
                  props.item.nickname,
                props.item.startDate,
                category,
                "animals"
              )}>
              <a>
                <SubListCardView
                  title="Animals"
                  category={category}
                  item={props.item}
                  style={[
                    {
                      marginTop: isMobileVersion ? 8 : 15,
                      height: 70,
                      borderRadius: 25,
                    },
                    isMobileVersion ? { height: 50, borderRadius: 16 } : {},
                  ]}
                  isMobileVersion={isMobileVersion}
                />
              </a>
            </Link>
          )} */}

          {isShowWall && (
            <WallCardView
              item={props.item}
              style={[
                {
                  marginTop: isMobileVersion ? 8 : 15,
                  height: 70,
                  borderRadius: 25,
                },
                isMobileVersion ? { height: 50, borderRadius: 16 } : {},
              ]}
              onPress={() => {
                navigateToProfileBottomScreen(
                  Constants.profileTabType.TAB_WALL
                );
              }}
              isMobileVersion={isMobileVersion}
            />
          )}
          {props.item?.host && (
            <VenueCardView
              item={props.item}
              style={[
                {
                  marginTop: isMobileVersion ? 8 : 15,
                  height: 70,
                  borderRadius: 25,
                },
                isMobileVersion ? { height: 50, borderRadius: 16 } : {},
              ]}
              isMobileVersion={isMobileVersion}
            />
          )}
          {isShowMemories && (
            <MemoryCardView
              item={props.item}
              style={[
                {
                  marginTop: isMobileVersion ? 8 : 15,
                  height: 70,
                  borderRadius: 25,
                },
                isMobileVersion ? { height: 50, borderRadius: 16 } : {},
              ]}
              onPress={() => {
                navigateToProfileBottomScreen(
                  Constants.profileTabType.TAB_MEMORIES
                );
              }}
              isMobileVersion={isMobileVersion}
            />
          )}
        </View>
      </div>
      {(category === Constants.categoryType.CATEGORY_FESTIVAL ||
        category === Constants.categoryType.CATEGORY_EVENT) &&
        lineupPoster && (
          <View
            style={{
              flex: 1,
              marginTop: isMobileVersion ? 20 : 50,
            }}>
            {lineupPoster && isMobileVersion && (
              <div className="unset-img">
                <Image
                  alt={`Lineup Poster ${props?.item?.title} ${moment(
                    props?.item?.startDate
                  ).format("YYYY")}`}
                  src={lineupPoster}
                  layout="fill"
                  className="custom-img"
                />
              </div>
            )}
            {lineupPoster && !isMobileVersion && (
              <div className="unset-img-600px">
                <Image
                  alt={`Lineup Poster ${props?.item?.title} ${moment(
                    props?.item?.startDate
                  ).format("YYYY")}`}
                  src={lineupPoster}
                  layout="fill"
                  className="custom-img"
                />
              </div>
            )}
          </View>
        )}
      {isShowMapView && !isMobileVersion && <div style={{ marginTop: 30 }} />}
      {isShowMapView ? (
        <MapView
          category={category}
          address={_address}
          postcode={postcode}
          street={address}
          location={_location}
          venue={venue}
          city={city}
          mapLink={mapLink}
          country={nationality}
          accomodationUrl={accomodationUrl}
          transportUrl={transportUrl}
          style={{
            marginTop: 20,
            marginHorizontal: 16,
            borderRadius: isMobileVersion ? 0 : 20,
          }}
          isMobileVersion={isMobileVersion}
        />
      ) : (
        <View style={{ height: 30 }} />
      )}

      {isShowVenues && (
        <CardList
          horizontal={false}
          items={props.item.venues}
          category={category}
          showInView={true}
          // onItemAction={({ type, item }) => {
          //   if (type == Constants.actionType.ACTION_VIEW) {
          //     navigation.push({
          //       pathname: "/profile/details",
          //       query: {
          //         category: category,
          //         id: item.id,
          //       },
          //     });
          //   }
          // }}
          style={{ marginTop: 16 }}
        />
      )}
      {isArtist && (
        <SpotifyListContainer
          spotifyId={spotifyId}
          isMobileVersion={isMobileVersion}
          style={{ marginTop: isMobileVersion ? 30 : 50 }}
        />
      )}
      {/*<EventRelated data={props.item}/>*/}
      {!isMobileVersion && isShowMapView && <div style={{ marginTop: 30 }} />}

      {isShowNearByFestival && (
        <NearByFestivalView
          item={props.item}
          category={category}
          isMobileVersion={isMobileVersion}
        />
      )}
      {category === Constants.categoryType.CATEGORY_PREFAB_HOMES && (
        <NearByOtherHomeView
          item={props.item}
          category={category}
          isMobileVersion={isMobileVersion}
        />
      )}
      {isShowNearByVenue && (
        <NearByVenueView
          item={props.item}
          category={category}
          isMobileVersion={isMobileVersion}
        />
      )}
      {category !== Constants.categoryType.CATEGORY_ARTIST && (
        <div style={{ marginTop: 18 }} />
      )}
      {category !== Constants.categoryType.CATEGORY_ARTIST &&
        category !== Constants.categoryType.CATEGORY_ANIMAL &&
        category !== Constants.categoryType.CATEGORY_PREFAB_HOMES && (
          <GuideProfile
            item={props.item}
            categoryType={
              category == Constants.categoryType.CATEGORY_EVENT
                ? Constants.categoryType.CATEGORY_FESTIVAL
                : category
            }
            isMobileVersion={isMobileVersion}
          />
        )}
      <div
        style={{
          marginTop:
            category !== Constants.categoryType.CATEGORY_ARTIST ? 14 : 30,
        }}
      />
      {!isOfficial && !isPro && (
        <BottomInfoView
          helpText={getDeclaimText(category)}
          style={{ marginHorizontal: isMobileVersion ? 30 : 16 }}
          isMobileVersion={isMobileVersion}
          updatedAt={props.item.updatedAt}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    paddingBottom: 16,
    marginHorizontal: -16,
  },
  readMoreContainer: {
    marginTop: 16,
    marginHorizontal: 24,
  },
  profileTagContainer: {
    marginTop: 8,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default ProfileAbout;
